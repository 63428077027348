import React from "react"

import Layout from "../components/layout"

const ContactPage = () => {
  return (
    <Layout>
      <h1> Contact </h1>
      <form method="post" action="#">
        <label>
          Name
          <input type="text" name="name" id="name" />
        </label>
        <label>
          Email
          <input type="email" name="email" id="email" />
        </label>
        <label>
          Subject
          <input type="text" name="subject" id="subject" />
        </label>
        <label>
          Message
          <textarea name="message" id="message" rows="5" />
        </label>
        <button type="submit">Send</button>
        <input type="reset" value="Clear" />
      </form>
      <p>
        The best way to reach me is via
        <a
          href="https://twitter.com/@pradipforever"
          target="_blank"
          rel="noopener noreferrer"
        >
          @pradipforever
        </a>
        on Twitter!
      </p>
    </Layout>
  )
}

export default ContactPage
